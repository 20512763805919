.full-container {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;
}

.game-container {
    position: relative;

    width: 100%;
    flex: 1;

    background: black;
}

.game-frame {
    border: none;
    z-index: 1;
    width: 100%;
    height: 100%;
}

.ad-playing-text {
    display: none;
    position: absolute;
    top: 0px;
    padding: 0.2em;
    text-align: center;
    color: white;
    background: black;
    font-size: 20px;
    width: 100%;
    z-index: 10000;
}

.ad-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    pointer-events: none;

    .ad-player-container {
        position: relative;
        height: 100%;
        width: 100%;
        // Max size of the ad player.
        max-height: 360px;
        max-width: 640px;
        margin: auto;
        pointer-events: initial;

            .fw_vpaid_slot {
                max-height: 360px !important;
                max-width: 640px !important;
                min-height: 360px !important;
                min-width: 640px !important;
            }
    }
}

#loading {
    position: absolute;
    top: 46%;
    left: 46%;
}

#loadingText {
    color: #ffffff;
}

#loading-img {
    height: 32px;
    width: 32px;
    margin-bottom: 10px;
}

/* Overlay animations */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.fadein {
    opacity: 0;
    animation: fadeIn 100ms ease-in forwards;
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
.fadeout {
    opacity: 1;
    animation: fadeOut 100ms ease-out forwards;
}

/* Overlay default styles */
.overlay {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    padding: 1rem;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.85);
    color: #ffffff;
    text-align: center;

    > div {
        display: flex;
        flex-direction: column;
        min-width: 80%;
        max-width: 100%;
        max-height: 100%;

        margin: auto;

        @media only screen and (max-height: 330px) {
            height: 100%;
        }

        hr {
            width: 100%;

            border: solid 1px #ffffff;
            box-sizing: border-box;
            opacity: 0.55;
        }
        hr:first-child {
            margin: 0 0 3rem 0;
            @media only screen and (max-height: 330px) {
                margin: 0 0 auto 0;
            }
        }
        hr:last-child {
            margin: 3rem 0 0 0;
            @media only screen and (max-height: 330px) {
                margin: auto 0 0 0;
            }
        }

        h1 {
            margin: 0 auto;

            overflow: visible;
            font-size: 29px;
        }
        h2 {
            margin: 0 auto;
            font-size: 2rem;
        }
        button.btn {
            width: 300px; // Image size x
            height: 68px; // Image size y
            max-width: 100%;
            max-height: 30%;

            margin: 0 auto;
            margin-top: 2rem;

            border: none;
            color: #ffffff;
            font-size: 28px;
            font-weight: bold;
            text-transform: uppercase;
            cursor: pointer;

            border-radius: 7px;

            background: #318700;
            background: linear-gradient(180deg, #4dd500 0%, #2f8300 4%, #318700 65%, #3a9e00 96%, #153a00 100%);
            &:hover {
                background: #389901;
                background: linear-gradient(180deg, #4dd500 0%, #369700 4%, #389901 65%, #45b901 96%, #205701 100%);
            }
            &:active {
                background: #358f01;
                background: linear-gradient(180deg, #205701 0%, #3a9e00 4%, #358f01 65%, #369700 96%, #44b900 100%);
            }
            @media only screen and (max-width: 1000px), (max-height: 512px) {
                width: 225px; // Image size x
                height: 56px; // Image size y
                font-size: 20px;
            }
            @media only screen and (max-width: 450px), (max-height: 250px) {
                width: 150px; // Image size x
                height: 48px; // Image size y
                font-size: 16px;
            }
        }
    }
}

/* Overlay-specific overrides */
.click-to-play,
.unsupported-browser {
    background: radial-gradient(#333333, #000000);
    a {
        color: #4da2ff;
    }
    a.notice-link {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        color: white;
    }
}

.unsupported-browser {
    p.not-supported {
        font-size: 1rem;
        margin-top: 1.5rem;
    }
}

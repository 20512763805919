html {
    background: transparent;
    margin: 0;
    height: 100%;
    width: 100%;
}

body {
    background: transparent;
    overflow:hidden;
    margin: 0;
    height: 100%;
    width: 100%;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
